<template>
  <div>
    <h2 class="mt-4 text-center">
      {{ $store.state.currentPoll.question }}
    </h2>

    <div class="vote-count">
      <p class="votes mb-0">
        {{ $store.state.currentPoll.submittedVotes }}
      </p>
      <p class="text">
        Teilnehmende.
      </p>
    </div>

    <div :class="$store.state.config.views[5].hashtagClass" v-if="$store.state.config.views[5].hashtag">
      {{ $store.state.config.views[5].hashtag }}
    </div>

    <template v-if="$store.state.currentPoll.options[0]">
      <template v-if="$store.state.currentPoll.options[0].title">
        <v-row>
          <v-col
            v-for="group in $store.state.currentPoll.options"
            :key="group.id"
            cols="12"
            lg="6"
            >
              <h3 class="text-center mt-4 mb-4">
                {{ group.title }}
              </h3>
              <v-row
                class="option"
                v-for="item in group.options"
                :key="item.id"
              >
                <v-col
                  cols="3"
                  class="option-title"
                  >
                  {{item.title}}
                </v-col>
                <v-col
                  cols="6"
                  >
                  <v-progress-linear
                    v-model="item.percentage"
                    height="40"
                    >
                    <span v-if="$store.state.config.views[5].display === 'votes'">{{ item.votes }}</span>
                    <span v-else-if="$store.state.config.views[5].display === 'percentages'">{{ item.percentage }}%</span>
                    <span v-else>{{ item.votes }} ({{ item.percentage }}%)</span>
                  </v-progress-linear>
                </v-col>
              </v-row>
          </v-col>
        </v-row>
      </template>
      <template v-else v-for="group in $store.state.currentPoll.options">
        <v-row
          class="option"
          v-for="item in group.options"
          :key="item.id"
        >
          <v-col
            cols="3"
            class="option-title"
            >
            {{item.title}}
          </v-col>
          <v-col
            cols="6"
            >
            <v-progress-linear
              v-model="item.percentage"
              height="40"
              >{{ item.votes }} ({{ item.percentage }}%)
            </v-progress-linear>
          </v-col>
        </v-row>
      </template>
    </template>
  </div>
</template>

<script>
export default {
  name: 'Results'
}
</script>

<style lang="scss">
  .results {
    h1 {
      font-size: 5.5rem;
    }

    h2 {
      margin-bottom: 6rem;
      margin-right: 15rem;
      font-size: 1.75rem;
      font-weight: var(--results-body-font-weight);
      font-style: var(--results-body-font-style);
    }

    h1,
    h3,
    .hashtag,
    .vote-count,
    .v-progress-linear__content {
      font-family: var(--headline-font);
      text-transform: var(--headline-transform);
      font-weight: var(--headline-font-weight);
      font-style: var(--headline-font-style);
      color: var(--headline-color);
    }

    .hashtag {
      background: var(--results-hashtag-color);
      color: var(--results-body-color);
      position: absolute;
      width: 9rem;
      height: 4rem;
      left: 0;
      top: 4rem;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 2rem;

      &.slanted:after {
        position: absolute;
        right: 0;
        top: 0;
        margin-right: -8px;
        content: "";
        width: 0;
        height: 0;
        transform: rotate(360deg);
        border-style: solid;
        border-width: 0 0 64px 8px;
        border-color: transparent transparent transparent var(--results-hashtag-color);
      }

      &.rotated {
        height: 70px;
        transform: rotate(-90deg);
        left: -50px;
        top: 8rem;
      }
    }

    .option {
      margin-bottom: 1rem;
    }

    .option-title {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      text-align: right;
      font-size: 1.5rem;
    }

    .vote-count {
      width: 200px;
      position: absolute;
      top: 150px;
      right: 80px;
      text-align: right;

      .votes {
        font-size: 5rem;
      }

      .text {
        font-size: 2.5rem;
        line-height: 1.05;
      }
    }

    .v-progress-linear {
      display: block;
      overflow: visible;
      pointer-events: none;

      .v-progress-linear__background {
        display: none;
      }

      .v-progress-linear__determinate, {
        display: inline-block;
        position: relative;
        margin-right: 10px
      }

      .v-progress-linear__content {
        display: inline-block;
        position: absolute;
        width: 12rem;
        right: -13rem;
        left: auto;
        top: -5px;
      }

      .v-progress-linear__determinate {
        background-color: var(--results-bar-color) !important;
      }

      .v-progress-linear__content {
        color: var(--results-bar-color)
      }
    }
  }
</style>
