<template>
  <div class="view results">
    <h1 class="text-center">Abstimmungsergebnis</h1>
    <div v-if="$store.state.connectionStatus === 'connected'">
      <Results />
    </div>
    <div v-else-if="$store.state.connectionStatus === 'connecting'" class="pt-4">
      <p class="text-center">
        Verbindung wird aufgebaut...
      </p>
    </div>
    <div v-else class="pt-4 red--text text-center">
      <p>
        Du bist mit keinem Meeting verbunden!
      </p>
      <v-btn
        @click="goToLogin()"
        class="pa-6 mb-6 mr-6"
        color="#19A229"
        dark>
        Verbinden
      </v-btn>
    </div>
  </div>
</template>

<script>

import Results from '@/components/Results.vue'

export default {
  name: 'ResultView',
  components: {
    Results
  },
  methods: {
    goToLogin () {
      this.$router.push('/')
    }
  }
}
</script>

<style lang="scss">
  .results {
    height: 100%;
    font-size: 2rem;
    cursor: none;
    padding: 4rem 8rem;
    background-color: var(--background-color);
    text-transform: var(--results-body-transform);
    color: var(--results-text-color);
    font-family: var(--results-body-font);
    font-weight: var(--results-body-font-weight);
    font-style: var(--results-body-font-style);
  }
</style>
